@import url('variables.css');

.name,
.description {
  margin-bottom: 1rem;
  margin-top: 0;
}

.name {
  font-family: var(--font-serif);
  font-size: min(17vw, 5em);
  line-height: 0.85em;
}

.namechanger {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.namechanger.transitioning {
  opacity: 0;
  transform: translateY(-4px);
}

.description {
  font-size: 1.25em;
}

.timeline,
.contact-info {
  list-style-type: none;
  padding-left: 0;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 0.125em;
}

.timeline {
  margin-bottom: var(--size-spacing-double);
  margin-top: var(--size-spacing-double);
}

.timeline-item:not(:last-child) {
  margin-bottom: var(--size-spacing-half);
}

.description,
.contact-method,
.experience .duration,
.experience .responsibilities,
.experience .org {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  padding: calc(var(--size-text-padding) / 4) var(--size-text-padding);
}

.experience .org:before {
  content: "@";
  margin-right: 0.125em;
}

@media (max-width: 768px) {
  .personal-info {
    padding: calc(var(--size-border-body) * 2) var(--size-border-body) var(--size-border-body);
  }

  .description {
    font-size: 1.15em;
  }
}
