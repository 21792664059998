@import url('variables.css');

.visualization-container {
  border-width: var(--size-border-body);
  border-style: solid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  transition: background-color 200ms ease-in-out;
  z-index: -1;
}

.visualization-canvas {
  width: 100%;
  height: 100%;
}

.visualization-canvas canvas {
  display: block;
}

.visualization-canvas .loading {
  font-size: 0.5em;
  position: absolute;
  z-index: -2;
}

.market-change {
  margin: 0;
}

.market-change .percentChangeAmount {
  display: inline-block;
  position: relative;
  padding-right: 1em;
}

.market-change.positive .percentChangeAmount::after { 
  content: '\2191';
  position: absolute;
  right: 0;
}

.market-change.negative .percentChangeAmount::after {
  content: '\2193';
  position: absolute;
  right: 0;
}

.market-info {
  font-size: 0.75em;
  opacity: 0.2;
  padding: var(--size-spacing-half);
  position: absolute;
  bottom: 0;
  right: 0;
}