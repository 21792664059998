:root {
    --font-serif: "Bodoni 72", "Bodini MT", serif;
    --font-size-base: 16px;
    --size-border-body: 1em;
    --size-spacing-base: 1rem;
    --size-spacing-half: calc(var(--size-spacing-base) / 2);
    --size-spacing-quarter: calc(var(--size-spacing-base) / 4);
    --size-spacing-double: calc(var(--size-spacing-base) * 2);
    --size-text-padding: 4px;
    --color-border-day: white;
    --color-border-night: black;
  }