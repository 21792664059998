@import url('variables.css');

/* Day */

body.day {
  color-scheme: light;
}

body.day:root {
  --safari-toolbar: var(--color-border-day);
}

body.day,
body.day a {
  color: var(--color-border-night);
}


body.day .description,
body.day .duration,
body.day .org,
body.day .responsibilities,
body.day .contact-method {
  background-color: var(--color-border-day);
}

body.day .visualization-container {
  border-color: var(--color-border-day);
}

body.day .loading {
  color: var(--color-border-day);
}

/* Night */

body.night {
  color-scheme: dark;
}

body.night:root {
  --safari-toolbar: var(--color-border-night);
}

body.night,
body.night a {
  color: var(--color-border-day);
}

body.night .description,
body.night .duration,
body.night .org,
body.night .responsibilities,
body.night .contact-method {
  background-color: var(--color-border-night);
}

body.night .visualization-container{
  border-color: var(--color-border-night);
}

body.night .loading {
  color: var(--color-border-night);
}