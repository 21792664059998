@import url('variables.css');
@import url('daynight.css');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, monospace;
}

a:hover,
a:focus {
  text-decoration: none;
}

a:active {
  outline: 1px dotted;
}

.container {
  position: relative;  
  width: calc(100% - var(--size-border-body) * 2);
  margin: var(--size-border-body) auto;
  padding-bottom: var(--size-spacing-double);
}

@media (min-width: 768px) {
  .container {
    width: 100%;
    height: calc(100dvh - var(--size-border-body) * 2);
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}